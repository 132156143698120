<template>
  <div class="home">
    <div class="bottom" v-show="bottom">
      <div class="left_box"><router-link :to="'/about/' + id"><img :src="dx[id].headerimg" alt=""></router-link></div>
      <div class="center_box">
        <div class="center_title">
            <router-link :to="'/about/' + id">
            {{ title }}
            </router-link>
        </div>
        <div class="center_ifo">{{localhost }}</div>
      </div>
      <div class="right_box ">
        
        <div class="gb"   @click="gbb">关闭</div>
        <a :href="vr" v-if="dx[id].vr"><img src="../assets/vr.png" alt="">
        全景</a>
      </div>

    </div>
    <div id="container" style="width:23.5rem;height:40rem"></div>

  </div>
</template>

<script>
import router from '@/router';


export default {
    name: 'HomeView',
    mounted() {
        this.initMap();
        var a = document.querySelector('canvas+div:last-child')
      a.style.display = 'none'
    },
    
	data(){ 
		// 此处的this指向Vue实例
		return {
			bottom:false,
            title:'',
            localhost:'',
            vr:'',
            img:'',
            id:'0',
            dx : [
    {
        "id":'0',
        "title": "白鹤岭",
        "time":'全天',
        "ipone":"13489893298，15717969868",
        "localhost":"南康区最北边的隆木乡福田村。",
        "introduce":' 白鹤岭与遂川县交界，海拔1042米，是南康境内第一高峰，登上白鹤岭据说可以远眺，茫茫五百里井冈山，素有南康阿里美誉的古村落，邹家地就在她的崇山峻岭之中。白鹤岭有一片近似草原地貌的山体，十几头耕牛正悠然觅食其中此时天高云淡，四周层峦叠嶂仿佛走进了一个高原牧场，美不胜收。山下有隆木白鹤岭家庭农庄，体验农家美味。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/2e28383f419cb607218198f7d9dd78fd.png',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/5466cb4e8a5b5ef3185a4f9bf09bed90.jpg','https://ynk.yocrea.cn/upload/20220524/5610505fc7825bafd27f40386045326b.jpg','https://ynk.yocrea.cn/upload/20220608/a4be050149eb1186d522b75b4d657212.jpg','https://ynk.yocrea.cn/upload/20220608/1b35591475fdfe6fff020880141e6d4f.jpg'],
        "vr":'https://c3cxcdkot.720think.com/t/c3cxcdkot'
    },
    {
        "id":'1',
        "title": "莲花山",
        "time":'全天',
        'ipone':'',
        "localhost":"南康区坪市乡与上犹县紫阳乡交界处",
        "introduce":'莲花山最具魅力和观赏价值的属自然奇石景观，山中众多的巨石仿佛天造神设一般竞相崛起，惟妙惟肖，栩栩如生。比如有猴头思过，痴熊西望，巨龟卧山，螳螂跳涧，鸿运石门，镇门神龟，蟾蜍望月，棋盘仙迹，擎天石柱，天书石、天磨石、天惊石，仙人解板石、风动石、天柱石、天门石、石岩蛙、音乐泉、海龟岩、木鱼石、观音洞等等，无数的奇洞暗岩，天然神石更是让人感叹大自然的神奇，使你一睹为快，留连忘返。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/8ce41b2478e5d09780d02b0795f03d3e.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/957fc5b0af040a89703eea482437b355.jpg','https://ynk.yocrea.cn/upload/20220524/3fd9fd9c77234592c2e1848a1491ecf5.png'],
        "vr":'https://fd2tkhvtt.720think.com/t/fd2tkhvtt?def_sid=1529668'
    },
    {
        "id":'2',
        "title": "谭邦古城",
        "time":'全天',
        'ipone':'0797-6792912',
        "localhost":"南康区坪市乡与上犹县紫阳乡交界处",
        "introduce":'谭邦古城谭邦古村位于江西省南康区坪市乡是一个古韵浓郁的客家古村，古韵的产生总是需要时间的沉淀。谭邦古村距离南康和赣州市区都是稍远的可以说是个很偏僻的古村落，走进谭邦能看到一栋栋熟悉的老屋，青砖马头墙在阳光下多了些古建筑的美。谭邦古村远离繁华闹市处在群山环绕之间，这里有典型的客家民居和淳朴生活。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/be357d7b753993ed4c776abddd0edccb.png',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/839bf331b4c490f04b190b0f28db9f1c.png','https://ynk.yocrea.cn/upload/20220524/eee803ccce83a7c62a5cca0bbe4ba3a5.png','https://ynk.yocrea.cn/upload/20220524/64eb9e8968f5f67599c7583bf863532d.png'],
        "vr":'https://b4aqoxmom.720think.com/t/b4aqoxmom'
    },
    {
        "id":'3',
        "title": "卢屋古村",
        'ipone':'',
        "localhost":"南康区唐江镇卢屋村",
        "introduce":'卢屋村，位于江西四大名镇之一的赣州市南康区唐江镇，是一个人口大村，现有人口1.1万人。该村是一座人文积淀厚重的客家名村。这里自古人文鼎盛，人才荟萃，遗迹颇多。光滑的鹅卵石街，高高耸立的封火砖墙，花树蓬勃的庭院，雕梁画栋的祠堂，构着起一道古朴而亮丽的风景。',
        "headerimg":require('../assets/lwc/1.jpg'),
        "imglist":[require('../assets/lwc/2.jpg'),require('../assets/lwc/3.jpg'),require('../assets/lwc/4.jpg'),require('../assets/lwc/5.jpg')],
        "vr":''
    },
    {
        "id":'4',
        "title": "红四军整顿军风会议旧址",
        "time":'',
        'ipone':'',
        "localhost":"南康区唐江镇卢屋村居委会枣子树下11号",
        "introduce":'1930年3月20日，在红军第一次攻打赣州失利后，时任政治委员的毛泽东和军长朱德率红四军第一、二、四纵队到达南康县唐江镇休整。',
        "headerimg":require('@/assets/hsj.png'),
        "imglist":[require('@/assets/hsj.png')],
        "vr":''
    },
    {
        "id":'5',
        "title": "潭口暴动旧址",
        "time":'全天',
        'ipone':'',
        "localhost":"赣州市南康区金智花园东南侧约230米",
        "introduce":'根据党的“八七”会议精神和中共江西省委制定的“全省总暴动计划”，在中共赣南特委的领导下，1928年2月16日深夜，罗贵波、兰广孚、廖贵潭等7人率领100多人暴动武装，按照暴动前制定的暴动计划，首先攻打驻潭口真君庙的国民党警察队，打死打伤反动警察各一名，暴动农民缴获步枪2支，胜利占领潭口镇，暴动取得了成功。 2月17日，工农革命委员会领导群众将潭口镇上肖厚载等几家土豪的财物没收，分发给贫苦群众。下午，工农革命委员会召开群众大会，庆祝暴动成功。此时，驻赣州国民党军开来一个营的部队包围了潭口镇，廖贵潭、罗贵波等暴动领导人立即指挥暴动武装掩护群众分散转移，决定暴动队伍分散隐蔽，以保存革命力量。 这次暴动遭到国民党反动派的疯狂镇压，少数未来得及转移的群众遭到逮捕，暴动队员罗起模、彭右生被捕后，竟被敌人剖腹挖心，英勇牺牲。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/6f5a8d409a934d5b75bd9cb36a7d63be.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/b768b58849b823b2ed7697bcb02db154.jpg','https://ynk.yocrea.cn/upload/20220524/fbb4505d6716ddddb538e5de2b6145c5.png'],
        "vr":'https://8f5jhjbxj.720think.com/t/8f5jhjbxj?def_sid=1516726'
    },
    {
        "id":'6',
        "title": "金谭生态农庄",
        "time":'8:30-20:30',
        'ipone':'0797-6588318',
        "localhost":"南康区镜坝镇老镜坝村",
        "introduce":'南康区金潭生态农庄成立于2012年3月23日，农庄位于镜坝镇老镜坝村，占地100多亩，距南康市区仅8公里，交通便利，地理环境优越，集餐饮、垂钓、休闲、娱乐为一体。金潭生态农庄是一家大型综合性的农家休闲地，有着众多娱乐休闲、户外拓展、乡村体验项目；还有各种当地美食，有自家种养的果蔬家禽，自己制作的土特产品，是非常赞的休闲度假农庄。农庄通过带动当地农民发展农家乐和其他旅游服务。两年来，间接提供就业岗位100个以上。企业以裕群农业专业合作社为依托，以家庭农户为单位，按照一亩土地为一股的形式吸纳农户加入合作社股份，通过“公司+合作社+农民”的运作模式，有效地带动周边群众参与乡村旅游建设。农庄还充分发挥自身优势担当社会责任，一方面聘请了20多位精准扶贫的贫困户到农庄就业，帮助他们走上脱贫道路，另一方面带动周边100多位农户发展种植业、养殖业。农庄手工制作的豆腐乳、剁辣椒、辣椒酱、泡大蒜、泡辣椒、瓦角酥、芋圆等，都是通过电商扶贫代收代销特色农产品，线上线下同步销售，促进农民增收,将特色农业经营之路越走越宽。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220316/4d3993a10920c72a01576ad71864c061.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/7383c4441023c9b2df5afd1cd01b0417.jpg','https://ynk.yocrea.cn/upload/20220524/8dba6e5c1139568f2ee48b2f10af23ac.jpg','https://ynk.yocrea.cn/upload/20220524/f6af7269c7973c1ebb5a91917f7e1c52.jpg','https://ynk.yocrea.cn/upload/20220618/ea2a970598d000485fdb1c346e98cb5a.jpg','https://ynk.yocrea.cn/upload/20220618/d2c9cc4cb8e6ade79db7ce00bf0793ee.jpg','https://ynk.yocrea.cn/upload/20220618/5b29778a8f36d79b74cf1e4bf4f4196f.png','https://ynk.yocrea.cn/upload/20220618/515537d936abe9590bcac977b8c0bd73.png'],
        "vr":'https://2afwakiww.720think.com/t/2afwakiww'
    },
    {
        "id":'7',
        "title": "家具小镇",
        "time":'8:00-22:00',
        'ipone':'0797-6792856',
        "localhost":"南康家居小镇",
        "introduce":'南康家居小镇是南康区为推动千亿家具产业集群由“制造”向“智造”，由“家具”向“家居”，由“木材买全球”向“家具卖全球”转型升级，进而打造世界家居集散地和现代家居城的力作。小镇规划建设5平方公里，核心区2平方公里。其设计整合了清华大学、厦门大学、中科院等10多所一流科研院校设计单位的优质资源，体现了“三生”（生产、生活、生态）融合和“产城人文”四位一体，体现了全球化、民族风和家居个性',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/069824eb19d0bf4b066a24518fbf889b.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/9ec27c4ca1e5228232a20088c50e8cc7.jpg','https://ynk.yocrea.cn/upload/20220524/aa2d6198b8bdf9b75107385b8e1183ba.jpg','https://ynk.yocrea.cn/upload/20220524/ce9ea5b03bd69a734fb1ad549062527d.jpg','https://ynk.yocrea.cn/upload/20220524/db1b37903e222a5ed40a8145fb362fea.jpg'],
        "vr":'https://www.expoon.com/e/4kgrgajc0qr/panorama?from=singlemessage'
    },
    {
        "id":'8',
        "title": "赣州港",
        "time":'全天',
        'ipone':'',
        "localhost":"南康区赣州国际港",
        "introduce":' 赣州国际港是中国第八个内陆开放口岸，全国内陆第一个也是唯一一个进口木材直通口岸，全国内陆第一个国检监管试验区，也是国检缩小版的自贸区，中国中部除武汉之外的第二个内陆口岸，也是江西内陆国际贸易“单一窗口”试点。赣州国际港先后开辟了满洲里、二连浩特、阿拉山口、霍尔果斯4个出入境通道，辐射“一带一路”沿线18个国家和地区，为“全球买木材，家具卖全球”提供了一条便捷的物流通道。赣州国际港自2016年5月建成并投入使用以来，已取得了与19个国家和地区进出口货物互联互通的成绩。针对港内货物运输主要依靠内燃机车，为提高运输效率，打通赣州港的快车通道，铁路部门对赣州港、南康站至赣州港区间进行了电气化改造，为开行电力机车创造条件。近年来，赣州港以其线路辐射范围广、成本低等优势，不断吸引着当地以及周边省市的出口货源，实现了中欧班列开行数量成倍增长。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220706/8886d3ddd19c8c924832e993980f038e.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220706/18e0dcab3e6a11c05dbe9e52fc0b8e56.jpg','https://ynk.yocrea.cn/upload/20220706/bc478742aa10103a698976753c3ab331.jpg','https://ynk.yocrea.cn/upload/20220706/db954a598352b6739d0bb883b500129c.jpg','https://ynk.yocrea.cn/upload/20220706/a5a921d88e6a1430695bfa8c95764451.jpg','https://ynk.yocrea.cn/upload/20220706/46144cdaf43de4a1f504c31004dc278f.jpg','https://ynk.yocrea.cn/upload/20220706/f8afdc60773b8202c40678d65a2d8e23.jpg','https://ynk.yocrea.cn/upload/20220706/770a24fff5d6fc68bad53a0e6e7c739d.jpg'],
        "vr":''
    },
    {
        "id":'9',
        "title": "家具市场",
        "time":'8:00-22:00',
        'ipone':'',
        "localhost":"南康区家居市场",
        "introduce":'南康家具市场位于江西省赣州市南康区，主要为家居类产品的专业市场。南康为江西省赣州都市区副中心城市、宜居创业的生态园林城市，由赣州市代管。位于江西省南部，赣江西源章江流域的中下游。 南康形成了完善的木材加工基地、家具生产基地、家具贸易基地、相关配套市埸如木材、五金、皮革等交易市埸,规划布局日趋完善合理,成为了中部家具产业基地',
        "headerimg":'https://ynk.yocrea.cn/upload/20220706/93d5de2e44052e917fe8931ac6975a8e.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220706/02c002065e0a9a56286ede4df3f014c7.jpg','https://ynk.yocrea.cn/upload/20220706/7eb6536229b94ae449515d651174d8ba.jpg','https://ynk.yocrea.cn/upload/20220706/08e6cd8647e156bf6bd55b2e9c55a529.jpg','https://ynk.yocrea.cn/upload/20220706/1da3bc25980f5e0f7dcde6042d5766f9.jpg','https://ynk.yocrea.cn/upload/20220706/1da3bc25980f5e0f7dcde6042d5766f9.jpg','https://ynk.yocrea.cn/upload/20220706/675b22b35ae371c55162bde2053179b0.jpg','https://ynk.yocrea.cn/upload/20220706/74d84834d600e86962aa8b12460b41e4.jpg','https://ynk.yocrea.cn/upload/20220706/7fe7d0027a17538574634ced23d2aeb4.jpg'],
        "vr":'https://5171puemq.720think.com/t/5171puemq?def_sid=2231077'
    },
    {
        "id":'10',
        "title": "方言农庄",
        "time":'08:00-18:00',
        'ipone':'13870735812',
        "localhost":"南康区朱坊乡胜利村",
        "introduce":'南康区方言农庄始建于2010年，归属于赣州市方言农业发展有限公司，地处南康区朱坊乡东大门——胜利村，交通便利，地理环境好，距南康城区仅11公里，距赣州市区20多公里。农庄被数百亩山林环抱，毗邻一座国家小（二）型水库（胜利水库），是一个四季绿水青山、鸟语花香，集餐饮、垂钓、休闲、娱乐为一体的生态园林休闲农庄。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/c63875da5b06b0db336e4a07d05cd97f.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/8b194902fef53d416eaa1627c6bea631.jpg','https://ynk.yocrea.cn/upload/20220524/dc28e6e82b7b18df993159c7adbfb829.jpg','https://ynk.yocrea.cn/upload/20220524/09b2f836fb278eb571ae1c3300934fd9.jpg','https://ynk.yocrea.cn/upload/20220524/84ae9a3dae374def9f5312b8a894034d.jpg','https://ynk.yocrea.cn/upload/20220524/a6524fcdebf4789087d6453d616c7500.jpg','https://ynk.yocrea.cn/upload/20220524/c8eaf158521b83633ada378ae8a189e5.jpg','https://ynk.yocrea.cn/upload/20220524/2b4e0e632656670a13b95b982f062d26.jpg','https://ynk.yocrea.cn/upload/20220524/e706f5a542c2222c05e5b88bea625d6c.jpg','https://ynk.yocrea.cn/upload/20220524/adabe798d318592b8bb9dd62a0154331.jpg','https://ynk.yocrea.cn/upload/20220524/99b367020ed6f7b4f3b7f59d67f3d1e3.jpg','https://ynk.yocrea.cn/upload/20220524/59b4fc2aede4d40addac24d4d0360b3e.jpg'],
        "vr":'https://980bh2evk.720think.com/t/980bh2evk'
    },
    {
        "id":'11',
        "title": "烈士陵园",
        "time":'',
        'ipone':'',
        "localhost":"龙回镇龙回村井塘坑组石孜岭",
        "introduce":'1959年建于龙回乡石孜岭上。青砖砌筑，通高7米，碑座边长3米。碑基占地面积70平方米。碑顶塑五角红星，碑身正面竖刻“革命烈士纪念碑”七个大字，背面竖刻“革命烈士永垂不朽”八个大字，碑座各面刻有序文及279位烈士英名。',
        "headerimg":require('../assets/lsly.png'),
        "imglist":[require('../assets/lsly.png')],
        "vr":''
    },
    {
        "id":'12',
        "title": "东山公园",
        "time":'全天',
        'ipone':'',
        "localhost":"南康区东山南路",
        "introduce":'南康东山公园位南康区东山南路。建成于2000年，占地248亩。 西临蓉江，北靠323国道， 东有东山路、金山路，处市区繁华地段。公园依山而建，充分利用东山原有的自然资源和文化底蕴，依山就势展开园林绿化和文化休闲等各项建设活动，最先建成文峰塔，文峰塔矗立园中，雄伟壮观。赞贤亭、双思亭、观澜亭、丛桂亭、太原亭分建各显要地段。巨型动物群雕和神话群雕，散立在相应景点。还建有水上乐园、儿童娱乐场、保龄球场、花园、灯光喷水池、迷宫，设有茶社、饭庄、商店。园内林木葱郁，鸟语花香，是城区集娱乐、休闲一体的综合性游乐场所。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/42fc502d86c76c93bcbd8cb95793d69f.png',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/d6a7dd0b6c3e68913b19d511aecfeaee.jpg','https://ynk.yocrea.cn/upload/20220524/96636d550c5d17dc1c39868bef53e907.jpg','https://ynk.yocrea.cn/upload/20220524/449c97f0ddf33832c0f8886962b96d76.jpg'],
        "vr":'https://85bwlqcjh.720think.com/t/85bwlqcjh'
    },
    {
        "id":'13',
        "title": "旭山公园",
        "time":'全天',
        'ipone':'',
        "localhost":"南康区北侧旭山岭",
        "introduce":'旭山公园占地面积约200亩，其中绿地面试8600平方米（不含水域面积），绿地率达65%。2007年以来，南康市委、市政府根据旭山公园现状、自然资源和人文资源优势，投入资金近3000万元，对其进行了综合改造，把旭山公园建设成为一座集休闲健身娱乐、革命传统教育、生态环境保护为一体的综合性城市公园。按照工程设计，旭山公园整体功能分四大区域，即：革命传统教育区、古迹文化区、休闲娱乐区、体育健身区。同时依山就势布置了人工湖、隧洞、花径、竹林等景观设施，整个公园体现出山水和谐、环境优美、精雕细琢，具有深厚的文化底蕴和历史积淀的城市公园景观风格。公园于2006年10月动工一期改造，2007年7月进入二期改造，已全部改造完工，是南康区市民休闲娱乐、锻炼健身和开展爱国主义教育的优良场所，已成为展示南康区城市形象的一张崭新名片。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/e5e1b707fd86980c397ccb4309a40707.png',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/88c78b527413e9bf13c1d638c4d4cee9.jpg','https://ynk.yocrea.cn/upload/20220524/9ee340a8f14373f962e0a7993964396a.jpg','https://ynk.yocrea.cn/upload/20220524/5bb15bbe668562dd3cd1dc741c642642.jpg','https://ynk.yocrea.cn/upload/20220524/6acb325dc13a9226c428e548b3c20fe4.png'],
        "vr":'https://056u0lqdp.720think.com/t/056u0lqdp?def_sid=1395224&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;ath=400.085694333001&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;atv=-11.8602342'
    },
    {
        "id":'14',
        "title": "文化艺术中心",
        "time":'',
        'ipone':'',
        "localhost":"南康区东山街办南水文化艺术中心E区1-2号西南方向70米",
        "introduce":'南康文化艺术中心是具有综合性、群众性、专业性、实用性的建筑群体，主要功能以大剧院、会议中心、大型超市组成。南康文化艺术中心座落在赣州市南康区南水新区，与区政府大楼同一中轴线上，北连占地130亩供市民休闲使用的文化广场，南面为2012年正在开工建设的体育中心，东边为南康中学，西边为南康国际酒店和现代住宅区。南康文化艺术中心占地75亩，建筑总面积38700m2，建筑占地10280m2，建筑总高47.5m，共分A、B、C、D四个区域，项目总投资8000万元人民币。',
        "headerimg":require('../assets/wh.png'),
        "imglist":[require('../assets/wh.png')],
        "vr":''
    },
    {
        "id":'15',
        "title": "南康大酒店",
        "time":'',
        'ipone':'0797-6677888',
        "localhost":"南康市中心天马山大道9号",
        "introduce":'酒店位于中国家俱制造名城——江西省南康市。地处南康市中心天马山大道9号，距离赣州黄金机场30分钟车程，赣州火车站40分钟车程。酒店总用地面积50亩，酒店建筑面积地上21000多平米，地下5400多平米。主楼楼高8层，两翼楼高6层，拥有各类豪华客房186间/套。餐饮容量1200多个餐位，风格各异的KTV包间12间，桑拿房15间，同时配备了设备先进的宴会厅，多功能厅，和3个小型会议厅，总容量可达800多人。此外还有健身房，泳池，商务中心，行政楼层，礼宾服务，商场，西餐厅，烧烤吧等。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220113/72c4c65b0b5d680f02dde6352830e7ba.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220113/5c1f748baf14e495f51b5834bbf8d839.png','https://ynk.yocrea.cn/upload/20220113/f0009cdec3c72d1481e06f9452aa5966.jpg','https://ynk.yocrea.cn/upload/20220113/4ab3dbc9f9e78e2fd8c2c0f966962200.jpg','https://ynk.yocrea.cn/upload/20220113/483dadbe573224600af785fbc1fd922f.jpg'],
        "vr":'https://www.expoon.com/e/32ja9zv7xcm/'
    },
    {
        "id":'16',
        "title": "观柚台农旅农庄",
        "time":'',
        'ipone':'18720858522',
        "localhost":"江西省赣州市南康区赤土畲族乡康坑孜",
        "introduce":'观柚台农旅庄园，江西省AAA乡村旅游点，江西省现代农业示范园，隶属于江西冠农现代农业开发有限公司，始创于2003年，现占地700余亩。庄园位于赣州市南康区赤土畲族乡观柚台，距南康城区仅11公里，紧邻赤土花园畲寨等景点，属南康中心城区“半小时休闲圈”。现拥有东魁大杨梅、鹰嘴桃、福建蜜柚、南康甜柚、西柚、赣南脐橙等多种类水果采鲜区，多品类鱼塘水库垂钓区，畲族风情农家乐，南康甜柚文化馆，户外拓展大草坪，临水木栈道露营区、农耕体验区等多种休闲农业旅游项目，并逐步开发打造山地户外拓展，山林木屋区、民宿区、综合培训基地、生态养殖等项目，致力于打造 南康本土休闲农业旅游综合型庄园。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/cb92098d86851f1ba32715319aeaf11e.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/bde944183ec067b831fa7e55737beb38.jpg','https://ynk.yocrea.cn/upload/20220524/d2cac38bbc9b7dfe479e500a8bb6e7b5.jpg'],
        "vr":'https://a8elmlicw.720think.com/t/a8elmlicw'
    },
    {
        "id":'17',
        "title": "赤土花果园农庄",
        "time":'8:30-21:00',
        'ipone':'18296878019',
        "localhost":"南康区赤土畲族乡旗山村",
        "introduce":'南康区赤土花果园农庄位于赤土畲族乡旗山村，占地面积1200多亩,其中脐橙500亩、甜柚400亩、杨梅200亩、桃子和李子100亩。农庄空气清新、风景秀丽，是集少数民族特色旅游、观光、休闲于一体的新型农庄和休闲胜地。农庄距南康城区11公里，约20分钟车程，交通便利。农庄围绕“生态农业、绿色养殖、农家休闲”这一核心，推出了以乡村石兰鸡、三黄虫子鸡、土鸭为主打食材的田园特色餐饮。农庄配有可容纳500人的游泳池以及可容纳80人的会议室，并提供相匹配的客房服务。为让游客切身感受农家气息，体味农耕乐趣，农庄推出“餐饮住宿”“果蔬采摘”“趣味烧烤”“休闲垂钓”“菜园畜禽认养”“ 拓展培训”“游泳健身”“民族体验”“ 农产品生产与加工”等项目，让您在游玩的同时可以亲身体验种植、收获的喜悦。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/8fcace5cfd88a9dbdc53e0fb6cb311b3.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/bc47f1858689ca628f7785988c4dcd86.jpg','https://ynk.yocrea.cn/upload/20220524/8f7f09e3a26dc1f01b77a0234e164ffa.jpg','https://ynk.yocrea.cn/upload/20220524/62e48205ac6cc1733ff68a53d2d27083.jpg','https://ynk.yocrea.cn/upload/20220524/4ec7b7e96be7c9d2099fe6d7a1c56f9d.jpg'],
        "vr":'https://eefuzkies.720think.com/t/eefuzkies'
    },
    {
        "id":'18',
        "title": "乐辣小城",
        "time":'09:00-18:00',
        'ipone':'18070101288',
        "localhost":"南康区横寨乡黄田村",
        "introduce":'黄田乐辣小城位于赣州市南康区横寨乡黄田村，交通便利，地理位置优越。园区占地500多亩，自然资源非常丰富，地形平坦，水塘密布，树木葱郁，竹海竹子婀娜多姿，赤水河犹如玉带环腰，更有小木屋点缀其中，若隐若现，一片远离城市喧嚣，安享精谧的桃源风光！这里环境优美，非常适合休闲度假，亲子互动，秋游春游，婚庆礼宴，企业团建，公司年会，研学旅行，同学聚会等活动。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/536c06ce23e97d8ebe87df4c25293ddb.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/2749accfee7d101503a0089c23fcfe36.jpg','https://ynk.yocrea.cn/upload/20220524/048f3148432100a9ea44f788c2f7a971.jpg','https://ynk.yocrea.cn/upload/20220525/e4b6de674d9630c2e5e818e0e2e637f8.jpg','https://ynk.yocrea.cn/upload/20220525/e6c158ca307d3c5ea0d03baa6aa2a587.jpg'],
        "vr":'https://eefmgroui.720think.com/t/eefmgroui'
    },
    {
        "id":'19',
        "title": "体育公园",
        "time":'',
        'ipone':'',
        "localhost":"南康体育公园",
        "introduce":'南康区体育公园是位于赣州市南康区境内，且总占地面积为215亩的公园，整个公园布局合理，分布着游泳馆、室外游泳池、室外儿童戏水池及室外运动场。体育馆等设施，历来在这里曾举办了江西省青少年羽毛球锦标赛和江西省第十四届运动会羽毛球比赛，同时也是市民平时休闲娱乐的绝佳去处。',
        "headerimg":require('../assets/tygy.png'),
        "imglist":[require('../assets/tygy.png')],
        "vr":''
    },
    {
        "id":'20',
        "title": "赤土畲族乡",
        "time":'',
        'ipone':'',
        "localhost":"赣州市南康区赤土畲族乡",
        "introduce":'赤土乡红壤甚多，又产赤金，赤土以此得名。2001年，撤销赤土镇建制，改设赤土畲族乡，成为赣州市唯一的少数民族乡，也是江西省少数民族人口最多的少数民族乡。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/ea2f66dd977bf50aa61034eb1e9f6ccf.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/6394c12f797e8d1b99c74cf7c88b7743.jpg','https://ynk.yocrea.cn/upload/20220524/0b5f45a0fc2e49793f1eb8595ef1e3a1.jpg'],
        "vr":'https://1f1asicap.720think.com/t/1f1asicap?def_sid=1353962'
    },
    {
        "id":'21',
        "title": "恐龙博物馆",
        "time":'',
        'ipone':'',
        "localhost":"南康区恐龙博物馆",
        "introduce":'赣州市南康区恐龙博物馆是恐龙化石资源的集中展示,是重要的保护与科普宣传工程,是认识、了解恐龙的一个重要窗口,是收集和宣传展示自然科学的艺术殿堂,也是探寻生命起源、探究地球演变、探索恐龙奥秘、探求科学知识,同时也是国家、地方性相关法律法规、政策宣传的教育平台。',
        "headerimg":require('../assets/klbwg.png'),
        "imglist":[require('../assets/klbwg.png')],
        "vr":''
    },
    {
        "id":'22',
        "title": "百家姓和谐城",
        "time":'',
        'ipone':'',
        "localhost":"南康区百家姓和谐城",
        "introduce":'整个和谐城分为三部分，一是仿清古建“和谐堂”，和谐堂由三个大殿和108间百家姓氏宗祠组成，建筑面积29998㎡。三大殿分别乾坤殿、始祖殿、熙和殿。108间宗祠，依山就势，分三排，与外循环路形成国字格局，寓“国泰民安”。二是恢弘宽广的和谐广场，占地81680㎡（风景秀丽、文明生态的园林广场）；三是50多亩宽水面的挹翠湖，碧波荡漾、波光滟潋，把总个和谐城映衬得尤其秀丽。另有六幅共312㎡长的大型艺术石雕墙，诠释了中华姓氏的根源、由来，镶在殿前大台阶中间的“和谐城大印”、“九龙石雕”、“和谐城颂”三幅石雕，是和谐城的点睛之景；大殿内安放的盘古、伏羲、炎帝、黄帝、尧、舜六尊铜像，雍容庄严。整个和谐堂飞檐斗拱，气势恢宏，青墙黄瓦，金碧辉煌。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/6d0a0e4b862d1a5c467d46a9e5c5e0c8.png',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/ad202d15c6f5ba1cd77bac0f341f4174.jpg','https://ynk.yocrea.cn/upload/20220524/8a857cc2f4a89df75c4989d0970ae361.jpg','https://ynk.yocrea.cn/upload/20220524/e6ac3c83efac479dcb44109e8e5cc507.jpg','https://ynk.yocrea.cn/upload/20220524/60715b14bb884e802af4cca625acadc5.jpg'],
        "vr":'https://50ce5uzsi.720think.com/t/50ce5uzsi?def_sid=1394079'
    },
    {
        "id":'23',
        "title": "万亩甜柚基地",
        "time":'',
        'ipone':'',
        "localhost":"南康区赤土畲族乡",
        "introduce":'赤土畲族乡地处“中国甜柚之乡”南康的西南部，区划面积157平方公里，辖17个行政村（其中富田、花园、河坝为畲族村）、1个社区，人口5万余人，其中少数民族人口10115人，为全省八个民族乡中面积最大、人口最多的少数民族乡。全乡呈“七山两水半分田、半分道路和庄园”的资源格局，得天独厚的土壤和气候条件，让畲乡成为种植甜柚、脐橙的一块“天然特区”。这里，村村有果园；这里，处处有甜柚；这里，人人淳朴勤劳。现在全乡已经拥有万亩甜柚与万亩脐橙等特色产业基地，年产量150万公斤。',
        "headerimg":require('../assets/tyjd.png'),
        "imglist":[require('../assets/tyjd.png')],
        "vr":''
    },
    {
        "id":'24',
        "title": "龙回暴动旧址",
        "time":'',
        'ipone':'',
        "localhost":"南康区龙回镇",
        "introduce":'1927年10月底，南昌起义部队进入江西信丰、南康，在龙回歧岭一带休整。中共龙回党组织配合起义部队，打垮了龙回靖卫团，缴枪数支。1928年2月，在龙回建立了中共南康特区委，下辖18个党支部。1929年春，中国工农红军第5军来到龙回圩。中共龙回党组织密切配合，发动群众，扩红筹款。到5月底，建立了5个党支部，有党员130多人，农民协会组织普遍得到恢复和发展。12月22日，中共赣南特委召开南康、信丰等县党员干部会议，研究了在各县举行农民武装起义的问题，并决定成立西河暴动委员会，总指挥丘达三，政治委员曾人超。1930年2月，南康县第1支工农武装一赤卫队正式成立，队长朱伯璜。3月20日，西河暴动委员会政委曾人超，南康县赤卫队长朱伯璜率县赤卫队、龙回暴动队配合红4军第2纵队攻克南康县城。21日，红2纵队1个分队与县赤卫队从县城开赴龙回圩。当夜，在龙回圩召开干部会议，对龙回起义的时间、地点、计划和成立县革命委员会等问题作了详细研究和部署。22日，龙回武装起义按预定时间举行。这天正逢圩日，龙回地区及邻区乡的赤卫队员，群众，共计2000余人，从四面八方拥向龙回。西河暴动委员会在龙回圩真君庙圩坪上举行大会，号召劳苦大众行动起来，打倒国民党反动派，建立人民自己的政权。会上宣布南康县革命委员会成立，朱伯璜任主席，宣布龙回靖卫团起义，改编为县赤卫队。会后，起义队员将龙回土豪林培河游街示众并当场处决。龙回武装起义的胜利，震动了南康县广大乡村。接着，三益、浮石、赤士土等区乡先后举行了起义。到1930年底，全县有20多个乡举行了农民起义，建立了区、乡革命政权，并成立了县苏维埃政府。',
        "headerimg":require('../assets/lh.png'),
        "imglist":[require('../assets/lh.png')],
        "vr":''
    },
    {
        "id":'25',
        "title": "浮石窝坑乡村景点",
        "time":'',
        'ipone':'',
        "localhost":"南康区浮石乡",
        "introduce":' 蕉溪村位于南康区浮石乡西南部，属于省定“十三五”贫困村，与大山脑林区相连，全村总面积3.5平方公里，其中耕地面积680亩，山地面积6500亩。共有9个村民小组，总计1230人，其中党员25名。历史上曾是陈毅三年游击的根据地，苏东坡拜访田辟时也曾于此饮茶赋诗《舟次浮石》。 2019年12月25日，蕉溪村入选国家森林乡村。  2020年6月，赣州市委、赣州市人民政府决定：授予该村镇“第十届赣州市文明村镇”称号。  2021年11月，被江西省精神文明建设指导委员会办公室列入“第七届江西省文明村镇”候选公示名单。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/728f82b45a8a8f3e7e2dfcfd8825d660.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/53fc0cc335dbed70cc32901ef477658e.jpg','https://ynk.yocrea.cn/upload/20220524/ce7e9d5ae47e8b06dbd3b2b0ce8985ab.jpg','https://ynk.yocrea.cn/upload/20220524/ab17ee704c7e37973a47c926cde4d9f5.jpg','https://ynk.yocrea.cn/upload/20220524/7911c7a4710f4364d4eca09dd43831fe.jpg'],
        "vr":'https://2d5nqqdta.720think.com/t/2d5nqqdta?def_sid=1584735'
    },
    {
        "id":'26',
        "title": "大脑山自然保护区",
        "time":'',
        'ipone':'',
        "localhost":"南康市浮石乡",
        "introduce":'保护区生态环境优良，是大山脑林场的精华部分，森林覆盖率高达95%，天然常绿阔叶林多达4000亩，已查明有云豹、水鹿、穿山甲、南方红豆杉、伯乐树、福建柏、闽楠等国家重点保护野生动植物50余种，具有重要的生态保护价值。有寺庙和民间传说 [2] 。林区山峦重叠，幽谷深邃，溪流清澈，风光秀丽，是避暑、观光、疗养、回归自然的理想场所。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/cf08628aa25338ef591c709d0d2187f5.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/c44d49a6b80a2c9ebd5287c521937799.jpg','https://ynk.yocrea.cn/upload/20220524/759dcff49e5b662d860d9736fb860ca3.jpg'],
        "vr":'https://2a3py3gje.720think.com/t/2a3py3gje'
    },
    {
        "id":'27',
        "title": "国家级航空飞行基地",
        "time":'08:30-18:00',
        'ipone':'13904688005',
        "localhost":"南康区龙回镇坪沙村公主岭",
        "introduce":'国家级航空飞行营地位于南康区龙回镇坪沙村公主岭，经专业人员实地勘察和全国各地100多名滑翔爱好者连续7年试飞，营地可以确认全年风向稳定，风力适合，留空时间8小时以上，全年飞行时间可达280天，是中国南方俱佳飞翔场地，拥有内陆动力气流滑翔全国优势地位，是理想的滑翔伞定点和越野飞行场地，是得天独厚的稀缺资源。国家体育总局和省体育局领导多次来到营地调研指导，2020年将申报国家航空飞行营地。',
        "headerimg":'https://ynk.yocrea.cn/upload/20220524/da87245c88e984504dcf1913fca0521d.jpg',
        "imglist":['https://ynk.yocrea.cn/upload/20220524/b083f31222c086a9126b1eb8634b69a5.jpg','https://ynk.yocrea.cn/upload/20220524/5a4b61834eae7d1af4748678939e4818.jpg','https://ynk.yocrea.cn/upload/20220524/c30789a6e5fd71ceac0503d956a39c51.jpg','https://ynk.yocrea.cn/upload/20220524/328a29b59674bdaad11c913b078cdb53.jpg'],
        "vr":'https://0d7twgpgf.720think.com/t/0d7twgpgf'
    },
]


		}
    },
    methods: {
        gbb(){
                this.bottom = false;

        },

        huihua(){
            var path0 = [
                new TMap.LatLng(-90, 180),
                new TMap.LatLng(-90, -180),
                new TMap.LatLng(90, -180),
              	new TMap.LatLng(90, 180),
            ];
            var path1 = [
                new TMap.LatLng(24.517453, 118.13681),
                new TMap.LatLng(24.517453, 118.13681),
                new TMap.LatLng(24.518078, 118.137153),
                new TMap.LatLng(24.499256, 118.138269),
                new TMap.LatLng(24.498475, 118.149255),
                new TMap.LatLng(24.498944, 118.159383),
                new TMap.LatLng(24.506988, 118.160327),
                new TMap.LatLng(24.523153, 118.156207),
                new TMap.LatLng(24.520811, 118.148654),
                new TMap.LatLng(24.517999, 118.144792),
                new TMap.LatLng(24.517765, 118.137067),
            ];

            var polygon = new TMap.Polygon({
                clickable: true,//多边形是否可点击。
                cursor: 'crosshair',//鼠标在多边形内的光标样式。
                editable: true,//多边形是否可编辑。
                fillColor: '#ffffff',//多边形的填充色，可通过Color对象的alpha属性设置透明度。
                //fillColor: new TMap.Color(0, 0, 0, 0.5),
                map: map,//要显示多边形的地图。
                path: path1,//多边形的路径，以经纬度坐标数组构成。
                 // 效果如腾讯地图-图一
                //path: [path0, path1], // 效果如腾讯地图-图二
                strokeColor: '#000000',//多边形的线条颜色，可通过Color对象的alpha属性设置透明度。
                strokeDashStyle: 'dash',//多边形的边框样式。实线是solid，虚线是dash。
                strokeWeight: 5,//多边形的边框线宽。
                visible: true,//多边形是否可见。
                zIndex: 1000//多边形的zIndex值。
});
        },

        initMap() {
            var that = this
            var map = new TMap.Map('container', {
                center: new TMap.LatLng(25.660285,114.764837),
                disableDefaultUI: true ,
                minZoom:16, 
                maxZoom:16,
                zoom: 16,
                draggable: false, 
                // scrollwheel: false, 
                viewMode:'2D',
                baseMap: {
                    // type: 'satellite',
                    type: 'vector',	//类型：失量底图
	            features: ['base', 'building2d']  
                }
            });
            // this.huihua()





            // 手绘地图
            TMap.ImageTileLayer.createCustomLayer({
                layerId: '6555ba980822',
                map: map
            }).then(customLayer => {
                if (customLayer) {
                    // 成功创建个性化图层
                    console.log('done');
                }
                else {
                    // 创建失败，请查看控制台错误信息
                    console.log('fail');
                }
            });
            var markerLayer = new TMap.MultiMarker({
                map: map,
                //样式定义
                styles: {
                    //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                    "myStyle": new TMap.MarkerStyle({
                        "width": 25,
                        "height": 35,
                        "src": '../img/marker.png',
                        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                        "anchor": { x: 16, y: 32 }
                    })
                },
                //点标记数据数组
                geometries: [
                    {
                        "id": "0",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.665866,114.761845),
                        "properties": {
                            "title": "白鹤岭"
                        }
                    },
                    {
                        "id": "1",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.665460,114.762768),
                        "properties": {
                            "title": "莲花山"
                        }
                    },
                    {
                        "id": "2",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.665189,114.763454),
                        "properties": {
                            "title": "谭邦古城"
                        }
                    },
                    {
                        "id": "3",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.663430,114.765984),
                        "properties": {
                            "title": "卢屋古村"
                        }
                    },
                    {
                        "id": "4",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.663121,114.764719),
                        "properties": {
                            "title": "红四军整顿军风"
                        }
                    },
                    {
                        "id": "5",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.663179,114.767786),
                        "properties": {
                            "title": "潭口暴动旧址"
                        }
                    },
                    {
                        "id": "6",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.662464,114.766542),
                        "properties": {
                            "title": "金谭生态农庄"
                        }
                    },
                    {
                        "id": "7",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.662212,114.765105),
                        "properties": {
                            "title": "家具小镇"
                        }
                    },
                    {
                        "id": "8",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.662077,114.768579),
                        "properties": {
                            "title": "赣州港"
                        }
                    },
                    {
                        "id": "9",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.661671,114.767057),
                        "properties": {
                            "title": "家具市场"
                        }
                    },
                    {
                        "id": "10",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.661362,114.762574),
                        "properties": {
                            "title": "方言农庄"
                        }
                    },
                    {
                        "id": "11",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.661227,114.763218),
                        "properties": {
                            "title": "烈士陵园"
                        }
                    },
                    {
                        "id": "12",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.661169,114.766606),
                        "properties": {
                            "title": "东山公园"
                        }
                    },
                    {
                        "id": "13",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.660801,114.763711),
                        "properties": {
                            "title": "旭山公园"
                        }
                    },
                    {
                        "id": "14",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.660589,114.766992),
                        "properties": {
                            "title": "文化艺术中心"
                        }
                    },
                    {
                        "id": "15",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.660279,114.765706),
                        "properties": {
                            "title": "南康大酒店"
                        }
                    },
                    {
                        "id": "16",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.660395,114.761374),
                        "properties": {
                            "title": "观柚台农旅农庄"
                        }
                    },
                    {
                        "id": "17",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.660028,114.761995),
                        "properties": {
                            "title": "赤土花果园农庄"
                        }
                    },
                    {
                        "id": "18",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.660144,114.764161),
                        "properties": {
                            "title": "乐辣小城"
                        }
                    },
                    {
                        "id": "19",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.660337,114.767550),
                        "properties": {
                            "title": "体育公园"
                        }
                    },
                    {
                        "id": "20",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.659642,114.762617),
                        "properties": {
                            "title": "赤土畲族乡"
                        }
                    },
                    {
                        "id": "21",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.659719,114.764976),
                        "properties": {
                            "title": "恐龙博物馆"
                        }
                    },
                    {
                        "id": "22",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.659236,114.765684),
                        "properties": {
                            "title": "百家姓和谐城"
                        }
                    },
                    {
                        "id": "23",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.658018,114.766456),
                        "properties": {
                            "title": "万亩甜柚基地"
                        }
                    },
                    {
                        "id": "24",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.657302,114.767657),
                        "properties": {
                            "title": "龙回暴动旧址"
                        }
                    },
                    {
                        "id": "25",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.656877,114.763775),
                        "properties": {
                            "title": "浮石窝坑乡村景点"
                        }
                    },
                    {
                        "id": "26",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.656723,114.762446),
                        "properties": {
                            "title": "大脑山自然保护区"
                        }
                    },
                    {
                        "id": "27",
                        "styleId": 'myStyle',
                        "position": new TMap.LatLng(25.656433,114.764676),
                        "properties": {
                            "title": "国家级航空飞行基地"
                        }
                    },
                ]
            });
            // var eventClick = function(evt) {
            //   markerID.innerHTML = 'markerID' + evt.geometry.id;
            //   position.innerHTML = '当前marker位置' + evt.geometry.position.toString();
            // }
            // function addClick() {
            //   Text.innerHTML = '绑定点击时间';
            //   marker.on('click',eventClick)
            // }
            //设置infoWindow
            var infoWindow = new TMap.InfoWindow({
                map: map,
                position: new TMap.LatLng(39.984104, 116.307503),
                content: "<div><img src='https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/em.jpg'><p>Hello World!</p></div>"
            });
            // 标点图片
            // markerLayer.setStyles({ "myStyle": new TMap.MarkerStyle({
            //         "width": 40,
            //         "height": 40,
            //         "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerNew.png',
            //         "opacity": 0.5
            //     }) });
            // 标记点击事件
            
            markerLayer.on("click", function (evt) {
                console.log(evt);
                var id = evt.geometry.id.replace(/[^0-9]/ig,"")
                    that.id = id
                that.title = that.dx[id].title 
                that.localhost = that.dx[id].localhost 
                that.vr = that.dx[id].vr 
                that.img = that.dx[id].img 
                var lat = evt.latLng.getLat().toFixed(6);
                var lng = evt.latLng.getLng().toFixed(6);
                // map.setCenter(new TMap.LatLng(lat, lng));
                // map.zoomTo(17.5);
                that.bottom = true;
                //  infoWindow.open(); //打开信息窗
                //  console.log(evt.geometry.position,'p')
                //   infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
                //   infoWindow.setContent(evt.geometry.position.toString());//设置信息窗内容
            });
            // 地图点击事件
            map.on("click", function (evt) {
                // console.log('event is ', evt);
                var lat = evt.latLng.getLat().toFixed(6);
                var lng = evt.latLng.getLng().toFixed(6);
                console.log(lat, lng);
                // that.bottom = false;
            });

            // 缩放事件
            // map.on('zoom_changed',function(evt) {
            //     console.log('is ', evt);
            //     console.log(1)
            //     map.zoomTo(16);  
            //     // map.setCenter(25.660975, 114.765448);
            //     });

        },
    },
    components: { router }
}

</script>

<style scoped>
a .img{
    display: none !important;
}

#container {
    margin: auto;
}


img{
  width: 100%;
  height: 100%;
}

.center_title{
  font-weight: bolder;
  margin-top: 0.25rem;
}
.center_box{
  text-align: left;
  padding: 0 0.5rem ;
  height: 100%;
}

.center_ifo{
  font-size: 0.8rem;

}

.right_box{
  width: 2rem;
  height: 100%;
  font-size: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.gb{
    font-size: 0.8rem;
    position: absolute;
    top: 0rem;
    right: 0rem;
}

.right_box img{
  width: 2rem;
  height: 2rem;
}

.left_box{
  width: 5rem;
  height: 5rem;
}

.left_box img{
  width: 5rem;
  height: 5rem;
}

.home{
  position: relative;
}

a{
  color: inherit; text-decoration: none;
}

.bottom{
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;

  left: 50%;
  top: 50%;
  transform:translateX(-50%) translateY(-50%);

  width: 80%;
  height: 6.5rem;
  background-color: white;
  z-index: 99999999;
}


</style>